import React from "react";
import tw from "twin.macro";

const ModalContainer = tw.div`fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50`;
const CloseButtonContainer = tw.div`flex justify-end`;
const CloseButton = tw.button`text-gray-500 hover:text-gray-900`;
const Title = tw.h3`text-lg leading-6 font-medium text-gray-900`;
const ChildrenContainer = tw.div`mt-2 text-sm text-gray-500 py-5`;
const ModalContent = tw.div`relative mx-auto p-5 border w-96 shadow-lg rounded-md bg-white`;
const HeaderContainer = tw.div`flex justify-between items-center`;

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  console.log("open");
  return (
    <ModalContainer>
      <ModalContent style={{ top: "20%" }}>
        <HeaderContainer>
          <Title>{title}</Title>
          <CloseButton onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="20"
              height="20"
              viewBox="0 0 30 30"
              fill="currentColor"
            >
              <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"></path>
            </svg>
          </CloseButton>
        </HeaderContainer>
        {/*         
        <CloseButtonContainer>
          <CloseButton onClick={onClose}>X</CloseButton>
        </CloseButtonContainer>
        <Title>{title}</Title> */}
        <ChildrenContainer>{children}</ChildrenContainer>
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;
