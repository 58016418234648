import React, { useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { apiUrl } from "../../config.js";
import tw from "twin.macro";
import styled, { keyframes } from "styled-components";

const SubmitButton = styled.button`
  ${tw`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
  &:disabled {
    ${tw`bg-gray-500 cursor-not-allowed`}
  }
`;
const FormGroup = tw.div`flex flex-wrap py-3`;
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
const LoadingSpinner = styled.div`
  border: 2px solid white;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 24px;
  height: 24px;
  animation: ${spin} 2s linear infinite;
`;

function GetRequest(props) {
  const { tab, testable = false } = props;

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const publicExample = `async function getData() {
    const response = await fetch("${apiUrl}/${tab.id}");
    const data = await response.json();

    // will return an array of objects with the _lineNumber
    return data;
}`;

  const privateExample = `async function getData() {
    const response = await fetch("${apiUrl}/${tab.id}", {
        method: "GET",
        headers: {
            Authorization: "Bearer ${tab.bearerToken}"
        }
    });
    const data = await response.json();

    // will return an array of objects with the _lineNumber
    return data;
}`;

  const fnCode = tab.public ? publicExample : privateExample;

  const testRequest = async () => {
    setLoading(true);
    try {
      const getDataFunction = eval(`(${privateExample})`);
      const data = await getDataFunction();

      setResponse(JSON.stringify(data, null, 2));
    } catch (e) {
      console.error(e);
      setResponse(JSON.stringify({ error: e.message }, null, 2));
    }

    setLoading(false);
  };
  return (
    <>
      <SyntaxHighlighter showLineNumbers language="javascript" style={docco}>
        {fnCode}
      </SyntaxHighlighter>
      {testable && (
        <>
          <FormGroup>
            <SubmitButton onClick={() => testRequest()} type="button">
              {loading ? <LoadingSpinner /> : "Test Request"}
            </SubmitButton>
          </FormGroup>
          {response && !loading && (
            <SyntaxHighlighter showLineNumbers language="json" style={docco}>
              {response}
            </SyntaxHighlighter>
          )}
        </>
      )}
    </>
  );
}

export default GetRequest;
