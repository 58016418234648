import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import "react-tabs/style/react-tabs.css";

import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import SaaSProductLandingPage from "components/new/pages/HomePage.js";
import Table from "components/new/pages/Table.js";
import ContactUsPage from "components/new/pages/ContactUs/index.js";
import Docs from "components/new/pages/Documentation.js";
import Admin from "components/new/pages/Admin.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "components/new/pages/Dashboard";
import PrivacyPolicy from "components/new/pages/PrivacyPolicy";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  return (
    <Router>
      <Switch>
        {/* <Route path="/components/:type/:subtype/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/components/:type/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/thank-you">
          <ThankYouPage />
        </Route> */}
        <Route path="/admin">
          <Admin />
        </Route>
        <Route path="/docs">
          <Docs />
        </Route>
        <Route path="/privacy">
          <PrivacyPolicy />
        </Route>
        <Route path="/contact">
          <ContactUsPage />
        </Route>
        <Route path="/tables/:id">
          <Table />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/">
          <SaaSProductLandingPage />
        </Route>
      </Switch>
    </Router>
  );
}

// export default EventLandingPage;
// export default HotelTravelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

// export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

// export default MainLandingPage;
