import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/new/components/Header.js";
import ContactUsForm from "./Form.js";
import Footer from "../../Footer";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <GoogleReCaptchaProvider reCaptchaKey="6LfQgE0pAAAAAFI3Hzb3QVyrHlZ9zRUGwSIOPa9R">
        <ContactUsForm />
      </GoogleReCaptchaProvider>
      <Footer />
    </AnimationRevealPage>
  );
};
