import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/new/components/Header.js";
import { ReactComponent as SvgDecoratorBlob3 } from "../../../images/svg-decorator-blob-3.svg";
import { apiUrl, urls } from "../config.js";
import { api } from "../../../helpers/api.js";
import DataTable from "react-data-table-component";

const Container = tw.div`relative`;

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base mt-4 first:mt-0 font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`text-gray-100 shadow-lg hocus:bg-blue-600 hocus:text-gray-200`;
const ThreeColumnContainer = styled.div`
  ${tw`flex-col items-center md:items-stretch md:flex-row flex-wrap max-w-screen-xl mx-auto pb-20`}
`;
const HeaderContainer = tw.div`flex justify-between items-center w-full mx-auto py-20 md:py-24`;
const Heading = tw(SectionHeading)`w-full text-center`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const addZero = (num) => `0${num}`.slice(-2);

function parseDate(date) {
  let formatNum = (n) => `0${n}`.slice(-2);
  const d = new Date(date);
  return `${formatNum(d.getDate())}/${formatNum(
    d.getMonth() + 1
  )}/${d.getFullYear()} ${formatNum(d.getHours())}:${formatNum(
    d.getMinutes()
  )}`;
}

function getTotalUsage(item) {
  const { quota } = item;

  const currentYear = `${new Date().getFullYear()}`;
  const currentMonth = `${new Date().getMonth()}`;

  if (!quota) {
    return 0;
  }

  const months = Object.keys(quota[currentYear]);
  const usage = months.reduce((acc, month) => {
    if (month <= currentMonth) {
      acc += quota[currentYear][month];
    }
    return acc;
  }, 0);

  return usage;
}

const sortDate = (a, b) => {
  const dateAChunks = lastRequestDate(a).split("-");
  const dateBChunks = lastRequestDate(b).split("-");

  const dateA = new Date(
    dateAChunks[0],
    Number(dateAChunks[1]) - 1,
    dateAChunks[2]
  ).getTime();
  const dateB = new Date(
    dateBChunks[0],
    Number(dateBChunks[1]) - 1,
    dateBChunks[2]
  ).getTime();

  return dateA - dateB;
};

function lastRequestDate(item) {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  if (
    !item.usage ||
    !item.usage[currentYear] ||
    !item.usage[currentYear][currentMonth]
  ) {
    return "N/A";
  }

  const days = Object.keys(
    item.usage[currentYear] && item.usage[currentYear][currentMonth]
  );

  const biggestNumberInArray = Math.max(...days);

  return `${currentYear}-${addZero(currentMonth + 1)}-${addZero(
    biggestNumberInArray
  )}`;
}

export default () => {
  const [tableData, setTables] = useState([]);
  const [loading, setLoading] = useState(false);

  const ImpersonateRender = (props) => {
    console.log("props", props);
    return (
      <PrimaryLink
        onClick={() => {
          // save local storage
          window.localStorage.setItem("impersonate", props.value);
          window.location = "/dashboard";
        }}
        target="_blank"
      >
        Impersonate
      </PrimaryLink>
    );
  };

  useEffect(() => {
    async function getStats() {
      setLoading(true);
      const stats = await api(`${apiUrl}${urls.statsPage}`, {
        credentials: "include",
      });

      stats.sort((a, b) => {
        const lra = lastRequestDate(a);
        const lrb = lastRequestDate(b);

        if (lra === "N/A") {
          return 1;
        }

        if (lrb === "N/A") {
          return -1;
        }

        if (lra === lrb) {
          return getTotalUsage(b) - getTotalUsage(a);
        }

        return new Date(lrb) - new Date(lra);

        //return getTotalUsage(b) - getTotalUsage(a);
      });

      setLoading(false);
      setTables(stats);
    }
    getStats();
  }, []);

  const columns = [
    {
      name: "E-mail",
      selector: (row) => row.email,
      sortable: true,
      minWidth: "300px",
    },
    {
      name: "Tables",
      selector: (row) => row.tables?.length ?? "0",
      sortable: true,
    },
    {
      name: "Tabs",
      selector: (row) => row.tabs?.length ?? "0",
      sortable: true,
    },
    {
      name: "Limit",
      selector: (row) => row.limit,
      sortable: true,
    },
    {
      name: "Usage",
      selector: (row) => row.quotaUsage,
      sortable: true,
    },
    {
      name: "Last Request",
      selector: (row) => lastRequestDate(row),
      sortable: true,
      sortfunction: sortDate,
    },
    {
      name: "Impersonate",
      cell: (row) => <ImpersonateRender value={row.id} />,
    },
  ];

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <HeaderContainer>
          <Heading>Admin</Heading>
        </HeaderContainer>
        <ThreeColumnContainer>
          {loading ? (
            "Loading..."
          ) : (
            <DataTable
              columns={columns}
              data={tableData}
              defaultSortFieldId={6}
            />
          )}
        </ThreeColumnContainer>
        <DecoratorBlob />
      </Container>
    </AnimationRevealPage>
  );
};
