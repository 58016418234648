import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/new/components/Header.js";
import Footer from "../Footer";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>Privacy Policy</Heading>
          </HeadingRow>
          <Text>
            <h1>Privacy Policy for Zero Sheets</h1>

            <p>
              <strong>Effective Date:</strong> 2024-01-10
            </p>

            <h2>1. Introduction</h2>

            <p>
              Welcome to Zero Sheets! This Privacy Policy is designed to inform
              you about the types of information we may collect when you use our
              app, how we use and protect that information, and your rights and
              choices regarding your personal data.
            </p>

            <h2>2. Information We Collect</h2>

            <h3>2.1 User Provided Information</h3>
            <p>
              We may collect information that you provide when using Zero
              Sheets, such as your name, email address, and other relevant
              details required for app functionality. This information is used
              to identify users on our application.
            </p>

            <h3>2.2 Automatically Collected Information</h3>
            <p>
              We may automatically collect certain information about your
              device, including but not limited to your IP address, device type,
              and browser information.
            </p>

            <h2>3. How We Use Your Information</h2>

            <h3>3.1 Providing Services</h3>
            <p>
              We use the Google user data (email and name) to identify users on
              our application. Additionally, we use authorization to create,
              read, update, and delete spreadsheets to allow our users to create
              real-time API`s using their spreadsheets as "databases." However,
              we do not save the data inside the spreadsheets. We only save the
              spreadsheet id, name, its tabs ids, and its tabs names. The
              spreadsheet id is stored for the sole purpose of manipulating the
              data inside it later.
            </p>

            <h3>3.2 Compliance with Google API Services User Data Policy</h3>
            <p>
              We hereby disclose that Zero Sheets fully complies with the Google
              API Services User Data Policy, including the Limited Use
              requirements. Please review the full Google API Services User Data
              Policy at{" "}
              <a
                href="https://developers.google.com/terms/api-services-user-data-policy"
                target="_blank"
                rel="noreferrer"
              >
                Google API Services User Data Policy
              </a>{" "}
              for detailed information on the policy.
            </p>

            <h3>3.3 Communication</h3>
            <p>
              We may use your email address to communicate with you about
              updates, new features, or other relevant information related to
              Zero Sheets.
            </p>

            <h2>4. Data Sharing</h2>

            <h3>4.1 Third-Party Services</h3>
            <p>
              We may share information with third-party services that assist us
              in providing and improving Zero Sheets. These third parties are
              obligated to protect your information.
            </p>

            <h3>4.2 Legal Compliance</h3>
            <p>
              We may disclose your information if required to do so by law or in
              response to a valid request from a law enforcement agency.
            </p>

            <h2>5. Your Choices</h2>

            <h3>5.1 Opt-Out</h3>
            <p>
              You can opt-out of receiving promotional emails from us by
              following the instructions in those emails. Please note that even
              if you opt-out, you may still receive non-promotional emails
              related to your use of Zero Sheets.
            </p>

            <h2>6. Security</h2>

            <p>
              We take reasonable measures to help protect your information from
              unauthorized access, disclosure, alteration, or destruction.
            </p>

            <h2>7. Changes to this Privacy Policy</h2>

            <p>
              We reserve the right to update this Privacy Policy at any time. We
              will notify you of any changes by posting the new Privacy Policy
              on this page.
            </p>

            <h2>8. Contact Us</h2>

            <p>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at <a href="/contact">this page</a>.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
