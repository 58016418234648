import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/new/SubHeader.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import GetStarted from "components/new/components/GetStarted.js";
import Pricing from "components/new/components/PricingTable.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStartedCta from "components/cta/GetStarted";
import Footer from "components/new/Footer.js";
import codePrint from "images/code.png";
import macHeroScreenshotImageSrc from "images/create-new-api.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as ClockIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { usagePlans } from "../config.js";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <MainFeature
        subheading={<Subheading>Restful JSON API</Subheading>}
        imageSrc={codePrint}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <GetStarted
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        subheading={<Subheading>Build Fast</Subheading>}
        heading={
          <>
            Bring your prototypes to life{" "}
            <HighlightedText>with real data.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description:
              "Start for free and pay as you grow, cheaper than creating your own backend panel.",
            iconContainerCss: tw`bg-green-300 text-green-800`,
          },
          {
            Icon: ClockIcon,
            title: "Fast",
            description:
              "Rapidly build your ideas with real life data, faster than creating your own backend panel.",
            iconContainerCss: tw`bg-red-300 text-red-800`,
          },
        ]}
      />
      <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        description="Choose Your Path: Tailored API Solutions for Every Stage of Development"
        plans={[
          {
            name: "Free",
            price: "$0",
            isFree: true,
            duration: "Monthly",
            mainFeature: "Explore the Possibilities",
            primaryButtonText: "Get Started",
            features: [
              "1 API",
              "Unlimited Sheet Tabs",
              "Read, Write, Edit and Delete",
              "200 requests per month",
            ],
          },
          {
            name: "Scout",
            price: "$4.99",
            priceId: usagePlans.Scout,
            duration: "Monthly",
            mainFeature: "For Innovators on a Budget",
            features: [
              "Unlimited API`s",
              "Unlimited Sheet Tabs",
              "Read, Write, Edit and Delete",
              "400 requests per month",
              "24/7 support",
            ],
            featured: true,
          },
          {
            name: "Craft",
            price: "$19.99",
            priceId: usagePlans.Craft,
            duration: "Monthly",
            mainFeature: "Elevate Your Projects",
            features: [
              "Unlimited API`s",
              "Unlimited Sheet Tabs",
              "Read, Write, Edit and Delete",
              "2000 requests per month",
              "24/7 support",
            ],
          },
        ]}
      />
      {/* 
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Are all the templates easily customizable ?",
            answer:
              "Yes, they all are. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
          {
            question:
              "How long do you usually support an standalone template for ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
          {
            question: "What kind of payment methods do you accept ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
          {
            question:
              "Is there a subscribption service to get the latest templates ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
          {
            question: "Are the templates compatible with the React ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
          {
            question: "Do you really support Internet Explorer 11 ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
        ]}
      /> */}
      <GetStartedCta />
      <Footer />
    </AnimationRevealPage>
  );
};
