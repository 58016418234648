import React, { useState } from "react";
import Modal from "../../../components/Modal.js";
import tw from "twin.macro";
import styled, { keyframes } from "styled-components";
import { apiUrl, urls } from "../../../config.js";
import { api } from "../../../../../helpers/api.js";
import Alert from "../../../components/Alert.js";

const Form = tw.form`mx-auto w-full max-w-xs mx-0`;
const Label = tw.label`block text-gray-700 text-sm font-bold mb-2`;
const Textarea = tw.textarea`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`;
const SubmitButton = styled.button`
  ${tw`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
  &:disabled {
    ${tw`bg-gray-500 cursor-not-allowed`}
  }
`;
const FormGroup = tw.div`flex flex-wrap py-3`;
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled.div`
  border: 2px solid white;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 24px;
  height: 24px;
  animation: ${spin} 2s linear infinite;
`;

function NewApiModal({ isOpen, onClose, createTable, onSuccess }) {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  if (!isOpen) return null;

  const validateUrl = (url) => {
    if (!url) {
      return false;
    }
    return url.match(
      /https:\/\/docs\.google\.com\/spreadsheets\/d\/[A-Za-z0-9_-]+(\/|$|\/anything)/
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const newTable = await api(`${apiUrl}${urls.createUserTable}`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ spreadsheetUrl: url }),
    });
    if (newTable.error) {
      setError(newTable.error);
      setUrl("");
      setTimeout(() => setError(null), 2000);
      setLoading(false);
      return;
    }
    createTable(newTable);
    setLoading(false);
    setUrl("");
    onSuccess();
  };

  const isValidUrl = validateUrl(url);
  let btnContent = "Create API";
  if (loading) {
    btnContent = <LoadingSpinner />;
  } else if (!isValidUrl) {
    btnContent = "Enter a valid URL";
  }

  return (
    <Modal title="Create New API" onClose={onClose} isOpen={isOpen}>
      <Form onSubmit={handleSubmit}>
        <Label htmlFor="spreadsheet-url">Spreadsheet URL</Label>
        <Textarea
          id="spreadsheet-url"
          type="text"
          rows={4}
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter Spreadsheet URL"
        />
        {error && <Alert type="error">{error}</Alert>}
        <FormGroup>
          <SubmitButton disabled={!isValidUrl || loading} type="submit">
            {btnContent}
          </SubmitButton>
        </FormGroup>
      </Form>
    </Modal>
  );
}

export default NewApiModal;
