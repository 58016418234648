import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import GetRequest from "./GetRequest";
import PostRequest from "./PostRequest";
import DeleteRequest from "./DeleteRequest";
import PatchRequest from "./PatchRequest";

const InputContainer = tw.div`relative py-6 mb-2`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;

function RequestExample(props) {
  const { tab, testable, allowedMethods = [] } = props;
  const [method, setMethod] = useState("GET");

  useEffect(() => {
    if (!allowedMethods.includes(method) && allowedMethods.length > 0) {
      setMethod(allowedMethods[0]);
    }
  }, [method, allowedMethods]);

  if (allowedMethods.length === 0) return null;

  const methodComponent = (method) => {
    switch (method) {
      case "GET":
        return <GetRequest testable={testable} tab={tab} />;
      case "POST":
        return <PostRequest testable={false} tab={tab} />;
      case "DELETE":
        return <DeleteRequest testable={false} tab={tab} />;
      case "PATCH":
        return <PatchRequest testable={false} tab={tab} />;
      default:
        return <GetRequest testable={testable} tab={tab} />;
    }
  };

  return (
    <InputContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: "10px",
        }}
      >
        <Label htmlFor="name-input" style={{ position: "static" }}>
          How to use?
        </Label>
        <select
          id="select1"
          onChange={(e) => setMethod(e.target.value)}
          placeholder="Method"
          style={{ marginLeft: "10px", fontSize: "14px" }}
        >
          {allowedMethods.map((m) => (
            <option selected={m === method} value={m}>
              {m}
            </option>
          ))}
        </select>
        <div
          style={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }}
        >
          <a target="_blank" href="/docs">
            Documentation
          </a>
        </div>
      </div>
      {methodComponent(method)}
    </InputContainer>
  );
}

export default RequestExample;
