import React, { useState } from "react";
import { apiUrl, urls } from "../config.js";
import { api } from "../../../helpers/api.js";

export default function useLoggedUser() {
  const [user, setUser] = useState(null);

  React.useEffect(() => {
    async function checkAuthentication() {
      const user = await api(`${apiUrl}${urls.getAuthenticatedUser}`, {
        noRedirect: true,
        noErrorOnFail: true,
      });

      if (user.error) {
        return;
      }

      setUser(user);
    }

    checkAuthentication();
  }, []);

  const doLogout = async () => {
    const impersonated = window.localStorage.getItem("impersonate") ?? null;
    if (impersonated) {
      window.localStorage.removeItem("impersonate");
      window.location.href = "/admin";
    } else {
      await api(`${apiUrl}${urls.logout}`, {
        credentials: "include",
      });

      setUser(null);
      window.location.href = "/";
    }
  };

  return {
    user,
    doLogout,
  };
}
