function omit(obj, omitKeys) {
  return Object.keys(obj).reduce((result, key) => {
    if (!omitKeys.includes(key)) {
      result[key] = obj[key];
    }
    return result;
  }, {});
}

export async function api(url, options = {}) {
  const impersonate = window.localStorage.getItem("impersonate") ?? null;

  const newOptions = omit(options, ["noJsonParse", "noRedirect"]);
  const response = await fetch(url, {
    ...newOptions,
    headers: {
      ...(newOptions.headers ?? {}),
      ...(impersonate ? { "X-Api-Key": impersonate } : {}),
    },
    credentials: "include",
  });
  const noJsonParse = options && options.noJsonParse;
  const noRedirect = options && options.noRedirect;

  if (noJsonParse) {
    return response;
  }

  const data = await response.json();

  if (!noRedirect && data.error === "Unauthorized") {
    window.location.href = "/";
  }

  return data;
}
