import styled from "styled-components";
const WarningError = styled.div`
  color: #58151c;
  background-color: #f8d7da;
  border-color: #f1aeb5;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
`;

function Alert({ timeout, children }) {
  return <WarningError>{children}</WarningError>;
}

export default Alert;
