import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/new/components/Header.js";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { apiUrl, urls } from "../config.js";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings.js";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import RequestExample from "../components/RequestExample/index.js";
import { api } from "../../../helpers/api.js";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-3 py-1 sm:px-4 sm:py-2 lg:px-4 lg:py-2 mt-4 first:mt-0 sm:mt-0 sm:mr-4 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200`;
const Container = tw.div`relative`;
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;

const OneColumnContainer = styled.div`
  ${tw`flex flex-col flex-wrap md:justify-center max-w-screen-xl mx-auto`}
`;

const InputContainer = tw.div`relative py-6 mb-2`;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded border-2 border-gray-400 hover:border-primary-500 focus:outline-none w-3/5`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Heading = tw(SectionHeading)`w-full`;
const TabPanelWithPadding = tw(TabPanel)`pt-3`;
const RadioContainer = styled.div`
  & > input {
    margin-right: 10px; // adjust the value as needed
  }
`;
const RightAlignedDiv = styled.div`
  ${tw`text-right`}
`;

function TabConfig({ tab, patchTableTab }) {
  const [testable, setTestable] = useState(false);
  const allMethods = ["GET", "POST", "PATCH", "DELETE"];

  useEffect(() => {
    async function checkIfTestable() {
      const quota = await api(`${apiUrl}${urls.getUserQuota}`);

      // refact RequestExample to allow test request but return error
      const requestsLeft = quota.limit - quota.currentUsage;
      if (requestsLeft > 0) {
        setTestable(true);
      }
    }

    checkIfTestable();
  }, []);

  const patchRequest = async (patch) => {
    return api(`${apiUrl}/tables/${tab.tableId}/tabs/${tab.id}`, {
      method: "PATCH",
      body: JSON.stringify(patch),
    });
  };

  const changePermissions = (permission) => {
    const permissions = tab.permissions.includes(permission)
      ? tab.permissions.filter((p) => p !== permission)
      : [...tab.permissions, permission];
    patchTableTab({
      ...tab,
      permissions,
    });
    patchRequest({
      permissions,
    });
  };

  const makePublic = (isPublic = false) => {
    patchTableTab({
      ...tab,
      public: isPublic,
    });
    patchRequest({
      public: isPublic,
    });
  };

  return (
    <>
      <InputContainer>
        <Label htmlFor="name-input">Endpoint</Label>
        <Input
          id="name-input"
          type="text"
          name="name"
          readOnly
          onClick={(e) => e.target.select()}
          value={`${apiUrl}/${tab.id}`}
        />
      </InputContainer>
      <InputContainer>
        <Label htmlFor="name-input">Allowed HTTP Methods</Label>
        {allMethods.map((permission) => (
          <span
            key={permission}
            style={{
              marginRight: "10px",
            }}
          >
            <input
              onChange={() => changePermissions(permission)}
              type="checkbox"
              checked={tab.permissions.includes(permission)}
            />{" "}
            {permission}
          </span>
        ))}
      </InputContainer>
      <InputContainer>
        <Label htmlFor="name-input">Authentication</Label>
        <RadioContainer>
          <input
            type="radio"
            onClick={() => makePublic(true)}
            checked={tab.public}
            name="auth"
          />
          None (Public)
        </RadioContainer>
        <RadioContainer>
          <input
            type="radio"
            onClick={() => makePublic(false)}
            checked={!tab.public}
            name="auth"
          />
          Bearer Token
        </RadioContainer>
      </InputContainer>
      {!tab.public && (
        <InputContainer>
          <Label htmlFor="name-input">Bearer Token</Label>
          <Input
            id="name-input"
            type="text"
            name="name"
            readOnly
            value={tab.bearerToken}
            onClick={(e) => e.target.select()}
          />
        </InputContainer>
      )}
      <InputContainer>
        <RequestExample
          allowedMethods={tab.permissions}
          testable={testable}
          tab={tab}
        />
      </InputContainer>
    </>
  );
}

export default function Table() {
  let { id } = useParams();
  const [table, setTable] = useState({});
  const [tableTabs, setTableTabs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const tableData = await api(`${apiUrl}${urls.listUserTables}/${id}`, {
        credentials: "include",
      });

      const tabData = await api(`${apiUrl}${urls.listUserTables}/${id}/tabs`, {
        credentials: "include",
      });

      setTable(tableData);
      setTableTabs(tabData);
      setLoading(false);
    }
    fetchData();
  }, []);

  const deleteTable = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this table?"
    );
    if (confirmed) {
      await api(`${apiUrl}${urls.deleteTable}/${id}`, {
        method: "DELETE",
        noJsonParse: true,
        credentials: "include",
      });
      window.location.href = "/dashboard";
    }
  };

  const patchTableTab = (tableTab) => {
    const newTableTabs = tableTabs.map((tab) =>
      tab.id === tableTab.id ? tableTab : tab
    );
    setTableTabs(newTableTabs);
  };

  let tabs = "Loading tabs...";
  if (!loading) {
    if (tableTabs.length === 0) {
      tabs = "No tabs found";
    } else {
      tabs = (
        <>
          <RightAlignedDiv>
            <PrimaryLink href="#" onClick={() => deleteTable()}>
              Delete Table
            </PrimaryLink>
          </RightAlignedDiv>
          <Tabs defaultIndex={1}>
            <TabList>
              <Tab disabled>Tabs</Tab>
              {tableTabs.map((tab) => (
                <Tab>{tab.title}</Tab>
              ))}
            </TabList>
            <TabPanelWithPadding></TabPanelWithPadding>
            {tableTabs.map((tab) => (
              <TabPanelWithPadding>
                <TabConfig patchTableTab={patchTableTab} tab={tab} />
              </TabPanelWithPadding>
            ))}
          </Tabs>
        </>
      );
    }
  }

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ThreeColumnContainer>
          <Heading>{table.title}</Heading>
        </ThreeColumnContainer>
        <OneColumnContainer>{tabs}</OneColumnContainer>
      </Container>
    </AnimationRevealPage>
  );
}
