const stage = process.env.REACT_APP_ENV;

export const urls = {
  login: "/auth",
  listUserTables: "/tables",
  createUserTable: "/tables",
  getAuthenticatedUser: "/user",
  statsPage: "/stats",
  getMonthlyUsage: "/stats/monthly-usage",
  getUserQuota: "/stats/quota",
  impersonate: "/impersonate",
  deleteTable: "/tables",
  logout: "/logout",
  dashboard: "/dashboard",
};

const api = {
  local: "http://localhost:3000/dev",
  development: "https://api-dev.zerosheets.com/v1",
  production: "https://api.zerosheets.com/v1",
};

const devUsagePlans = {
  Scout: "price_1P8iEkIe2YTgTjANb5BS2o5L",
  Craft: "price_1P6WbxIe2YTgTjAN2ZGJLpOX",
};
const prodUsagePlans = {
  Scout: "price_1PBKweIe2YTgTjANu35wHSq9",
  Craft: "price_1P8ie5Ie2YTgTjANtZD0qi77",
};

export const usagePlans =
  stage === "production" ? prodUsagePlans : devUsagePlans;

export const apiUrl = api[stage];
