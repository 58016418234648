import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/new/components/Header.js";
import { Link } from "react-router-dom";
import { ReactComponent as SvgDecoratorBlob3 } from "../../../../images/svg-decorator-blob-3.svg";
import { apiUrl, urls } from "../../config.js";
import UsageProgressBar from "./UsageProgressBar";
import NewApiModal from "./NewApiModal";
import { api } from "../../../../helpers/api.js";
const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto pb-10`}
`;
const HeaderContainer = tw.div`flex justify-between items-center w-full mx-auto py-20 md:py-24`;
const Heading = tw(SectionHeading)`w-full text-center`;
const ButtonContainer = tw.div`flex justify-center items-center`;
const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100 cursor-pointer
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
  float-right
`;

const Column = styled.div`
  ${tw`w-full px-6 flex`}
`;

const CardLink = styled(Link)`
  ${tw`flex justify-between items-center mx-auto w-full px-4 py-4 border-2 border-dashed border-primary-500 rounded-lg mb-2 no-underline text-black hover:bg-gray-200 transition-all duration-200`}
  .title {
    ${tw`font-bold text-lg leading-none text-primary-500`}
  }
  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const formatDateString = (dateString) => {
  const date = new Date(dateString);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export default () => {
  const query = new URLSearchParams(window.location.search);
  const stripeError = query.get("stripeError");

  if (stripeError) {
    console.log(`stripeError`, stripeError);
  }

  const [cards, setTables] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  async function getTables() {
    setLoading(true);
    const tables = await api(`${apiUrl}${urls.listUserTables}`, {
      credentials: "include",
    });

    if (!tables) {
      return;
    }

    // sort by createdAt
    tables.sort((a, b) => {
      if (+new Date(a.createdAt) < +new Date(b.createdAt)) {
        return 1;
      }
      if (+new Date(a.createdAt) > +new Date(b.createdAt)) {
        return -1;
      }
      return 0;
    });
    setLoading(false);
    setTables(tables);
  }

  useEffect(() => {
    getTables();
  }, []);

  const createTable = (card) => {
    setTables([...cards, card]);
  };

  let tablesContent = "Loading...";
  if (!loading && cards.length === 0) {
    tablesContent = "You have no tables yet. Create one!";
  } else if (!loading && cards.length > 0) {
    tablesContent = cards.map((card, i) => (
      <Column key={i}>
        <CardLink to={`/tables/${card.id}`}>
          <span className="title">{card.title}</span>
          <span>Created at {formatDateString(card.createdAt)}</span>
        </CardLink>
      </Column>
    ));
  }

  return (
    <AnimationRevealPage>
      <NewApiModal
        createTable={createTable}
        onSuccess={() => {
          setIsOpen(false);
          getTables();
        }}
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
      />
      <Header />
      <Container>
        <HeaderContainer>
          <Heading>
            Your <span tw="text-primary-500">API`s</span>
            <ButtonContainer>
              <PrimaryLink onClick={() => setIsOpen(true)}>
                Create New API
              </PrimaryLink>
            </ButtonContainer>
          </Heading>
        </HeaderContainer>
        <ThreeColumnContainer>{tablesContent}</ThreeColumnContainer>
        <ThreeColumnContainer>
          {/* <UsageChart /> */}
          <UsageProgressBar />
        </ThreeColumnContainer>
        <DecoratorBlob />
      </Container>
    </AnimationRevealPage>
  );
};
