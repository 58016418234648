import React, { useState, useEffect } from "react";
import { apiUrl, urls } from "../../config.js";
import { api } from "../../../../helpers/api.js";
import styled from "styled-components";
import tw from "twin.macro";
import { Link } from "@mui/material";

const ProgressBarContainer = styled.div`
  ${tw`w-full flex items-center justify-center mr-4 ml-10`}
`;

const ProgressBarLabel = styled.div`
  ${tw`text-lg font-bold mr-4 w-1/5`}
`;

const ProgressBar = styled.div`
  ${tw`w-full h-4 relative bg-gray-200 mr-10`}
`;

const ProgressBarInner = styled.div`
  ${tw`h-full bg-blue-500 text-center text-white flex items-center justify-center`}
  width: ${(props) => props.width}%;
`;

export default () => {
  const [usage, setUsage] = useState({
    currentUsage: 0,
    limit: 0,
    renewAt: 1,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const quota = await api(`${apiUrl}${urls.getUserQuota}`);

      setUsage(quota);
      setLoading(false);
    }

    fetchData();
  }, []);

  let progress = Math.ceil((usage.currentUsage / usage.limit) * 100);
  if (progress > 100) {
    // WORKAROUND WHILE I DONT HAVE A SUBSCRIPTION SYSTEM
    progress = 100;
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const parseDays = (days) => {
    if (days === 1) {
      return "1 day";
    } else {
      return `${days} days`;
    }
  };

  const addExtensionToDay = (day) => {
    if (day === 1) {
      return "st";
    } else if (day === 2) {
      return "nd";
    } else if (day === 3) {
      return "rd";
    } else {
      return "th";
    }
  };

  const getRenovationMonth = (renovationDate) => {
    const today = new Date();
    let renovationMonth = today.getMonth();

    if (today.getDate() >= renovationDate) {
      renovationMonth++;
    }

    return renovationMonth;
  };

  const daysLeftToRenovation = (renovationDate) => {
    const today = new Date();
    let renovationMonth = getRenovationMonth(renovationDate);

    const renovation = new Date(
      today.getFullYear(),
      renovationMonth,
      renovationDate
    );
    const diff = renovation - today;

    return Math.ceil(diff / (1000 * 60 * 60 * 24));
  };

  return (
    !loading && (
      <>
        <ProgressBarContainer>
          <ProgressBarLabel>
            your usage ({usage.currentUsage}/{usage.limit}):
          </ProgressBarLabel>
          <ProgressBar>
            <ProgressBarInner width={progress}>
              {progress > 20 && `${progress}%`}
            </ProgressBarInner>
          </ProgressBar>
        </ProgressBarContainer>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <p>
            You still have {usage.limit - usage.currentUsage} requests left on
            your <b>{usage.usagePlan}</b> plan.{" "}
            <Link style={{ cursor: "pointer" }} href="/#pricing">
              [Upgrade]
            </Link>
          </p>
          <p>
            Renovation in {parseDays(daysLeftToRenovation(usage.renewAt))} (
            {monthNames[getRenovationMonth(usage.renewAt)]} {usage.renewAt}
            {addExtensionToDay(usage.renewAt)}).
          </p>
        </div>
      </>
    )
  );
};
