import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/new/components/Header.js";
import Footer from "../Footer";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryLink } from "components/misc/Links";
import preparationImg from "images/step1-preparation.png";
import connectImg from "images/connect-google-account.png";
import sheetUrl from "images/sheet-url.png";
import createApiImg from "images/create-api.png";
import createNewTableImg from "images/create-new-table.png";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

export default ({ headingText = "Documentation" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>
              Learn how to use our service with our documentation. If you have
              any questions, please{" "}
              <PrimaryLink href="/contact">contact us</PrimaryLink>.
            </p>

            <h1>Step by Step</h1>
            <h2>Preparation</h2>
            <p>
              First you need to prepare your Google Spreadsheets table.
              <b>
                You only need to have the first row as the table header, and the
                rest of the rows as the data.
              </b>{" "}
              You can also have multiple sheets in the same spreadsheet, and
              each sheet will be a table in Zero Sheets.
            </p>

            <p
              style={{
                marginTop: "2rem",
              }}
            >
              <Image src={preparationImg} />
            </p>
            <h2>Create your account</h2>
            <p>
              You can create your account by clicking the "Connect Your Google
              Account" button on the home page. The spreadsheets that you want
              to transform into an API <b>must be saved on your</b> Google
              Account.
            </p>
            <p>
              <Image src={connectImg} />
            </p>

            <h2>Create your API</h2>
            <p>
              Once your account is created, click on <b>Create New Table</b>,
              paste your Google Spreadsheet url and click on Create API.
            </p>

            <p>
              <Image src={createNewTableImg} />
            </p>

            <p>
              <Image src={createApiImg} />
            </p>

            <p>
              You can get the spreadsheet url acessing your Google Spreadsheet
              and copying it from the browser url input.
            </p>

            <p>
              <Image src={sheetUrl} />
            </p>

            <p>And you are done!</p>

            <h2>Usage</h2>
            <p>
              By default, Zero Sheets will import every spreadsheet sheet (tab)
              as a new API with the following configuration:
            </p>
            <p>
              <ul>
                <li>Only GET method is enabled</li>
                <li>Secured by a Bearer Token</li>
              </ul>
            </p>
            <p>
              You can change that acessing your Dashboard and clicking on the
              table API.
            </p>
            <p>
              Once you have your API configured, you can use it as any other
              API. You can use it on your frontend, backend, or even on your
              mobile app with any programming language with HTTP requests
              support.
            </p>

            <h2>Responses</h2>
            <p>
              The response will always be a JSON object with the following
              structure:
            </p>
            <SyntaxHighlighter
              showLineNumbers
              language="javascript"
              style={docco}
            >
              {JSON.stringify(
                [
                  {
                    "table column 1": "row 1 value for column 2",
                    "table column 2": "row 1 value for column 2",
                    _lineNumber: 2,
                  },
                  {
                    "table column 1": "row 2 value for column 2",
                    "table column 2": "row 2 value for column 2",
                    _lineNumber: 3,
                  },
                  {
                    "table column 1": "row 3 value for column 2",
                    "table column 2": "row 3 value for column 2",
                    _lineNumber: 4,
                  },
                ],
                null,
                2
              )}
            </SyntaxHighlighter>
            <p>
              Note that every row will have a <b>_lineNumber</b> property
              (automatically added by us) that will be the row number in the
              spreadsheet. This is useful if you want to update or delete a row
              in the spreadsheet.
            </p>
            <p>
              The column names will be the same as the spreadsheet column names.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
